
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import Card from '@/core/components/Tela/Card.vue';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarEmpresaCadastroCompartilhado from '@/components/MeuSistema/Empresas/SelecionarEmpresaCadastroCompartilhado.vue';
import SelecionarLocalPrestacaoServico from '@/components/Cadastros/PrestacaoServicos/SelecionarLocalPrestacaoServico.vue';
import SelecionarIssRetido from '@/components/Cadastros/PrestacaoServicos/SelecionarIssRetido.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { ITelaOperacao } from '@/core/models/ITelaOperacao';
import { ETipoPermissao } from '@/models/Enumeradores/MeuSistema/Usuarios/ETipoPermissao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { IServico, IServicoEmpresa } from '@/models/Entidades/Cadastros/PrestacaoServicos/IServico';
import ServicoServico from '@/servicos/Cadastros/PrestacaoServicos/ServicoServico';

export default defineComponent({
  name: 'ServicoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    operacao: {
      type: Object as () => ITelaOperacao,
      required: true,
    },
  },
  components: {
    Icone,
    Card,
    SelecionarEmpresaCadastroCompartilhado,
    RequisicaoModal,
    CampoNumerico,
    SelecionarLocalPrestacaoServico,
    SelecionarIssRetido,
  },
  emits: ['sincronizarRegistro', 'update:operacao', 'update:visivel'],
  setup(props, { emit }) {
    const {
      telaBase, obterPermissoes, preencherEmpresasComEstrategiaPermissaoDados, verificaConceitoParaApresentarEmpresas,
      defineEmpresasSelecionadasCadastroCompartilhado, preencherPermissoesDados, filtrarPermissaoDadosUsuarioMultiEmpresas, apresentarMensagemSucesso,
    } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
      defineNovaOperacao, defineTextoPadraoBotoes, sincronizarRegistro,
    } = useModalBase(props, emit);
    const servicoservico = new ServicoServico();
    telaBase.identificadorRecurso = 'CADASTRO_SERVICOS';
    telaBase.identificadorPermissao = 'PER_CADASTRO_SERVICOS';

    const state = reactive({
      servico: {} as IServico,
    });

    function objetoInicial() {
      state.servico = {} as IServico;
      state.servico.codigo = 0;
      state.servico.localPrestacaoServico = 1;
      state.servico.issRetido = 1;
      state.servico.aliquotaIss = 0;
      state.servico.aliquotaPis = 0;
      state.servico.aliquotaCofins = 0;
      state.servico.aliquotaInss = 0;
      state.servico.aliquotaIr = 0;
      state.servico.aliquotaCsll = 0;
      state.servico.empresas = [];
      state.servico.ativo = true;
      telaBase.empresasSelecionadas = [];
    }

    function preparaPersistenciaEmpresas() {
      const servicoEmpresas: IServicoEmpresa[] = [];
      if (telaBase.empresasSelecionadas.length > 0) {
        telaBase.empresasSelecionadas.forEach((codigoEmpresa) => {
          const empresaExistente = state.servico.empresas.find((c) => c.codigoEmpresa === codigoEmpresa);
          if (empresaExistente !== undefined) {
            servicoEmpresas.push(empresaExistente);
          } else {
            const servicoEmpresa: IServicoEmpresa = { codigo: 0, codigoServico: state.servico.codigo, codigoEmpresa };
            servicoEmpresas.push(servicoEmpresa);
          }
        });
      }
      state.servico.empresas = servicoEmpresas;
    }

    async function salvar(salvarNovo: boolean) {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };

      preparaPersistenciaEmpresas();
      apresentarBarraProgresso();

      if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
        retorno = await servicoservico.incluir(state.servico);
      } else if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
        retorno = await servicoservico.alterar(state.servico);
      }

      ocultarBarraProgresso();

      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Incluir) {
          sincronizarRegistro(EPermissaoDados.Incluir, retorno.codigoRegistro);
        } else {
          sincronizarRegistro(EPermissaoDados.Alterar, props.operacao.codigoRegistro);
        }
        apresentarMensagemSucesso(retorno.mensagem);
        if (salvarNovo) {
          objetoInicial();
          defineNovaOperacao(props.operacao);
        } else {
          modalBase.computedVisivel = false;
        }
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      objetoInicial();
      if (modalBase.computedVisivel) {
        if (props.operacao.listaPermissoesDados.length > 0) {
          await preencherPermissoesDados(props.operacao.listaPermissoesDados);
        } else {
          await obterPermissoes(ETipoPermissao.Dados);
        }
        await preencherEmpresasComEstrategiaPermissaoDados(props.operacao.tipoPermissaoDados, true);

        defineTextoPadraoBotoes(props.operacao.tipoPermissaoDados);
        if (props.operacao.tipoPermissaoDados === EPermissaoDados.Visualizar) {
          state.servico = await servicoservico.obter(props.operacao.codigoRegistro, props.operacao.empresaSelecionada);
          if (state.servico.empresas.length > 0) {
            state.servico.empresas.forEach((servicoEmpresa) => {
              telaBase.empresasSelecionadas.push(servicoEmpresa.codigoEmpresa);
            });
          }
        } else {
          await defineEmpresasSelecionadasCadastroCompartilhado(props.operacao.empresaSelecionada);
        }

        telaBase.permissaoDados = await filtrarPermissaoDadosUsuarioMultiEmpresas(telaBase.empresasSelecionadas);
        verificaConceitoParaApresentarEmpresas();
      }
      telaBase.carregando = false;
    });

    return {
      telaBase,
      props,
      modalBase,
      state,
      salvar,
      objetoInicial,
      EPermissaoDados,
    };
  },
});
