
import { defineComponent, ref } from 'vue';
import TelaPadraoCrud from '@/core/components/TelaPadraoCrud.vue';
import { useCrudBase } from '@/core/composables/CrudBase';
import { ECustomRenderRow } from '@/core/models/AntDesign/IColumn';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { EPermissaoDados } from '@/models/Enumeradores/MeuSistema/Usuarios/EPermissaoDados';
import ServicoModal from './ServicoModal.vue';
import ServicoServico from '@/servicos/Cadastros/PrestacaoServicos/ServicoServico';

export default defineComponent({
  name: 'Servico',
  components: {
    TelaPadraoCrud,
    ServicoModal,
  },
  setup() {
    const {
      crudBase, preencherEstrutura, exibirModal, sincronizaTelaCrud,
    } = useCrudBase();
    const servicoServico = new ServicoServico();

    const refCrudServico = ref<InstanceType<typeof TelaPadraoCrud>>();

    crudBase.estrutura = preencherEstrutura();
    crudBase.estrutura.apresentarEmpresas = true;
    crudBase.estrutura.verificarCadastroCompartilhado = true;
    crudBase.estrutura.botaoPrincipalAcao = 'Novo';
    crudBase.estrutura.mensagemExclusao = 'Você confirma a exclusão do serviço:';
    crudBase.estrutura.colunasGrade = [
      {
        title: 'Código', dataIndex: 'codigo', key: 'CodigoServico', position: 0, visible: false, ordering: true,
      },
      {
        title: 'Descrição', dataIndex: 'descricao', key: 'DescricaoServico', position: 1, visible: true, ordering: true, ellipsis: true, fixed: 'left', align: 'left', customRenderRow: ECustomRenderRow.LinkAcao,
      },
      {
        title: 'Atividade Econômica', dataIndex: 'atividadeEconomica', key: 'AtividadeEconomicaServico', position: 2, visible: true, width: 350, ellipsis: true, ordering: true, align: 'left',
      },
      {
        title: 'Alíquota ISS', dataIndex: 'aliquotaIss', key: 'AliquotaIssServico', position: 3, visible: true, ordering: true, align: 'right', width: 100,
      },
      {
        title: 'Status', dataIndex: 'ativo', key: 'AtivoServico', position: 4, visible: true, width: 100, align: 'left', customRenderRow: ECustomRenderRow.TagAtivo,
      },
      {
        title: 'Ações', key: 'acoes', position: 5, visible: true, fixed: 'right', width: 100, align: 'center', customRenderRow: ECustomRenderRow.IconeAcoes,
      },
    ];
    crudBase.estrutura.ordenacaoPadraoGrade.push({ identificador: 'Codigo', ordem: 'DESC' } as IOrdenacao);

    async function sincronizarRegistro(acao: EPermissaoDados, codigoRegistro: number) {
      if (refCrudServico.value !== undefined) {
        await refCrudServico.value.sincronizarRegistro(acao, codigoRegistro);
      }
    }
    return {
      crudBase,
      servicoServico,
      refCrudServico,
      exibirModal,
      sincronizaTelaCrud,
      sincronizarRegistro,
    };
  },
});
